<template>
<Dialog v-model:visible="visible" modal header="Create New Client" :style="{ width: '50vw' }">
    <!-- Client Name -->
    <p class="mt-3 font-bold">Name</p>
    <InputText class="w-full" v-model="clientInfo.name" />

    <!-- Client Logo -->
    <p class="mt-3 font-bold">Logo</p>
    <InputText class="w-full" v-model="clientInfo.logo"/>

    <!-- Client Identifier -->
    <p class="mt-3 font-bold">Identifier</p>
    <InputText class="w-full" v-model="clientInfo.identifier"/>

    <!-- Active -->
    <p class="mt-3 font-bold">Disabled</p>
    <ToggleButton v-model="clientInfo.disabled" onLabel="Disabled" offLabel="Enabled" onIcon="pi pi-times" offIcon="pi pi-check" />

    <template #footer>
        <Button class="w-full mt-2" label="Save" @click="save" :disabled="unsafeInputs()"/>
    </template>
</Dialog>
</template>

<script>
export default {
    name: "ClientModify",

    data() {
        return {
            visible: false,

            clientInfo: {
                id: null,
                name: "",
                logo: "",
                identifier: "",
                disabled: false,
            }
        }
    },

    methods: {
        show(clientInfo) {
            if (clientInfo !== undefined) {
                this.clientInfo = clientInfo;
            } else {
                this.init();
            }
            this.visible = true;
        },

        init() {
            this.clientInfo = {
                id: null,
                name: "",
                logo: "https://placehold.co/800?text=No\\nImage\\nFound",
                identifier: "",
            }
        },

        unsafeInputs() {
            return (
                this.clientInfo.name === null ||
                this.clientInfo.logo === null ||
                this.clientInfo.name.length === 0 ||
                this.clientInfo.logo.length === 0
            )
        },

        saveNew() {
            this.$factory.client.createClient(this.clientInfo).then(() => {
                this.$emitter.emit("good-toast", "Client created successfully");
                this.$emitter.emit('clients-refresh');
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Failed to create client.");
            }).finally(() => {
                this.visible = false;
                this.init();
            });
        },

        saveExisting() {
            this.$factory.client.updateClient(this.clientInfo).then(() => {
                this.$emitter.emit("good-toast", "Client updated successfully");
                this.$emitter.emit('clients-refresh');
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Failed to update client.");
            }).finally(() => {
                this.visible = false;
                this.init();
            });
        },

        save() {
            if (this.clientInfo.id === null) {
                this.saveNew();
            } else {
                this.saveExisting();
            }
        },
    },
}
</script>

<style scoped>

</style>
