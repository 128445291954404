<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- add new -->
            <div class="col-12">
                <div class="grid surface-card shadow-2 border-round p-3">
                    <div class="col-6">
                        <Button class="w-full mt-2 px-3" label="Create New Client" @click="modifyClient"/>
                    </div>
                    <div class="col-6">
                        <Button :class="showDisabled ? '' : 'p-button-outlined'" class="w-full mt-2" label="Disabled Clients" :icon="showDisabled ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showDisabled=!showDisabled" />
                    </div>
                </div>
            </div>

            <!-- clients -->
            <div v-for="client in this.filteredClients" v-bind:key="client.Id" class="col-12 md:col-6 lg:col-2" style="min-height: 100%">
                <router-link :to="{ name: 'Asset Viewer', params: {clientName: client.Name } }" style="text-decoration: none; color: inherit;" class="cursor-pointer">
                    <div v-ripple @contextmenu="onRightClick($event, client)" class="flex flex-column justify-content-between h-full surface-card shadow-2 border-round p-3 hover:surface-200 transition-duration-150 transition-colors p-ripple">
                        <img class="border-round" style="object-fit: contain; width: 100%; height: 100%; aspect-ratio: 1" v-bind:src="client.Logo" :style="{filter: currentTheme === 'light' ? 'invert(100%)' : 'invert(0%)'}">
                        <p class="text-center">
                            {{ client.Name }}<br>
                            {{ client.active }} Active / {{ client.inactive }} Inactive / {{ client.prospect}} Prospect
                        </p>
                    </div>
                </router-link>
            </div>

        </div>
    </div>
</div>
<ClientModify ref="modifyClient" />
<ContextMenu ref="contextMenu" :model="contextMenuItems"/>
</template>

<script>
import ClientModify from "@/components/modals/ClientModify";

export default {
    name: "Clients",
    components: {
        ClientModify,
    },

    computed: {
        filteredClients() {
            return this.clients.filter(client => {
                if (this.showDisabled) {
                    return true;
                } else {
                    return !client.Disabled;
                }
            })
        }
    },

    data() {
        return {
            loading: false,

            clients: [],

            // Right click menu
            contextMenuItems: [
                { label: 'Update', icon: 'pi pi-fw pi-cog', command: () => this.updateClient()},
            ],
            lastSelectedClient: null,

            currentTheme: this.$store.state.theme,
            showDisabled: false,
        }
    },

    mounted() {
        this.getClients();

        this.$emitter.on("clients-refresh", () => {
            console.debug("clients-refresh");
            this.getClients();
        });
    },

    methods: {
        getClients() {
            this.clients = [];
            this.$factory.client.getClientsAndLogos().then(data => {
                this.clients = data;
                console.debug(this.clients)
            })
        },

        modifyClient() {
            this.$refs.modifyClient.show();
        },

        updateClient() {
            console.debug("Update client: ", this.lastSelectedClient)
            let clientObj = {
                id: this.lastSelectedClient.Id,
                name: this.lastSelectedClient.Name,
                logo: this.lastSelectedClient.Logo,
                identifier: this.lastSelectedClient.Identifier,
                disabled: this.lastSelectedClient.Disabled,
            }
            this.$refs.modifyClient.show(clientObj);
        },

        onRightClick(event, client) {
            this.lastSelectedClient = client;
            this.$refs.contextMenu.show(event);
        },
    },

    watch: {
        '$store.state.theme': function (newTheme) {
            this.currentTheme = newTheme;
        }
    }
}
</script>

<style scoped>

</style>
